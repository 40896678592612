import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/about": [8],
		"/(seo)/all-malls": [~5],
		"/(seo)/all-malls/[mallId]": [~6],
		"/brochures/m/[id]": [9],
		"/brochures/ru/[id]": [10],
		"/businesses": [12],
		"/business/[slug]": [11],
		"/demo/[business]": [13,[2]],
		"/elections/municipal": [17],
		"/elections/regional": [18],
		"/elections/[mallId]": [14,[3]],
		"/elections/[mallId]/municipal": [15,[3]],
		"/elections/[mallId]/regional": [16,[3]],
		"/eortologio": [19],
		"/events": [20],
		"/events/m/[id]": [21],
		"/farmakeia": [22],
		"/info/[id]": [23],
		"/job-candidates": [24],
		"/jobs": [25],
		"/malls": [26],
		"/news/greece": [27],
		"/news/local": [28],
		"/news/municipality": [29],
		"/news/org": [30],
		"/password-reset": [31],
		"/portfolio": [32],
		"/post/[id]": [33],
		"/profile": [34],
		"/radio": [35],
		"/share/[shopId]": [36],
		"/shop-list": [38],
		"/shops-brochure": [39],
		"/shop/[mall]/[shopId]": [37],
		"/survey": [40],
		"/terms": [41],
		"/tv": [42],
		"/welcome": [43],
		"/[...path]": [7]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';